<template>
  <ui-component-modal
    :modalTitle="$t('Components.Events.EventProgram.Main_Title')"
    :onClickCancel="onClickCancel"
    :showModal="showModal"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickSave="saveEvent"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div class="form-program">
        <div class="input-program has-margin-bottom">
          <div class="select">
            <select v-model="startMinutes">
              <option
                v-for="(time, index) in timeOptions"
                :key="'time_' + index"
                :value="time"
              >
                {{ time | minutesToTime }}
              </option>
            </select>
          </div>
          <input
            class="input input-description"
            type="text"
            v-model="description"
            placeholder="Program"
            maxlength="200"
            v-on:keyup.13="addToEventProgram"
          />
          <button @click="addToEventProgram" class="button is-success">
            <span class="icon">
              <font-awesome-icon :icon="['fas', 'plus']" style="color: white" />
            </span>
          </button>
        </div>

        <div ref="wrapperProgram" class="wrapper-program">
          <transition-group name="inbox" mode="out-in">
            <div
              v-for="(program, index) in sort(mProgramItems)"
              :key="'sorted_' + index"
              class="row has-margin-bottom"
            >
              <div class="select">
                <select v-model="program.StartMinutes">
                  <option
                    v-for="(time, index) in timeOptions"
                    :key="'time_' + index"
                    :value="time"
                  >
                    {{ time | minutesToTime }}
                  </option>
                </select>
              </div>
              <input
                class="input"
                type="text"
                v-model.lazy="program.Description"
                placeholder="Program"
                maxlength="200"
              />
              <a @click="deleteItem(index)" class="has-icon">
                <span class="icon">
                  <font-awesome-icon
                    :icon="['fas', 'trash-alt']"
                    style="color: red"
                  />
                </span>
              </a>
            </div>
          </transition-group>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import eventProvider from '@/providers/event'

export default {
  components: {},
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
    eventId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mProgramItems: this.$objectHelper.cleanSource(
        this.$store.getters['eventStore/getEventData']
      ).ProgramItems,
      startMinutes: 540,
      description: '',
      timeOptions: this.buildTimeMinutesOptions(0, 1440, 5),
      timerWrapperProgram: null,
      mEvent: this.$objectHelper.cleanSource(
        this.$store.getters['eventStore/getEventData']
      ),
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData']),

    sortedProgramItems: {
      get() {
        if (this.mProgramItems) {
          return this.mProgramItems.slice().sort((a, b) => {
            // If the first item has a higher number, move it down
            // If the first item has a lower number, move it up
            if (a.StartMinutes > b.StartMinutes) return 1
            if (a.StartMinutes < b.StartMinutes) return -1

            // If the number is the same between both items, sort alphabetically
            // If the first item comes first in the alphabet, move it up
            // Otherwise move it down
            if (a.Description.toLowerCase() > b.Description.toLowerCase())
              return 1
            if (a.Description.toLowerCase() < b.Description.toLowerCase())
              return -1
          })
        }
        return []
      },
      set(val) {
        ////console.info(val)
      },
    },
  },

  created() {
    // this.mProgramItems = this.mEvent.ProgramItems
  },

  methods: {
    ...mapMutations('eventStore', ['setEventData']),

    sort() {
      if (this.mProgramItems) {
        return this.mProgramItems.slice().sort((a, b) => {
          // If the first item has a higher number, move it down
          // If the first item has a lower number, move it up
          if (a.StartMinutes > b.StartMinutes) return 1
          if (a.StartMinutes < b.StartMinutes) return -1

          // If the number is the same between both items, sort alphabetically
          // If the first item comes first in the alphabet, move it up
          // Otherwise move it down
          if (a.Description.toLowerCase() > b.Description.toLowerCase())
            return 1
          if (a.Description.toLowerCase() < b.Description.toLowerCase())
            return -1
        })
      }
      return []
    },

    /**
     * Delete program item
     */
    deleteItem(index) {
      Vue.delete(this.mProgramItems, index)
    },

    /**
     * Build time select options
     */
    buildTimeMinutesOptions(open, close, minutesSteps) {
      let output = []
      let oneMinute = 1

      for (let i = open; i < close; i = i + minutesSteps) {
        output.push(i)
      }
      return output
    },

    /**
     * Add new program item to program items
     */
    addToEventProgram() {
      if (this.description.trim() !== '') {
        this.mProgramItems.push({
          Id: 0,
          EventId: this.eventId,
          StartMinutes: this.startMinutes,
          Description: this.description,
        })
        this.scrollDownToLastMessage()
      }
      this.description = ''
    },

    /**
     * Update program items in event store
     */
    saveEvent() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
        self.mEvent.ProgramItems = self.mProgramItems

        eventProvider.methods
          .updateEvent(self.mEvent)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true
              self.setEventData(response.data)

              let t = setTimeout(() => {
                self.onClickCancel()
                clearTimeout(t)
              }, 1500)
            }
          })
          .catch((e) => {
            ////console.log(error.response)
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    /**
     * Scroll down to last message
     */
    scrollDownToLastMessage() {
      if (this.timerWrapperProgram) {
        clearTimeout(this.timerWrapperProgram)
      }
      this.timerWrapperProgram = setTimeout(() => {
        this.$refs.wrapperProgram.scrollTop = 99999
        clearTimeout(this.timerWrapperProgram)
      }, 150)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template';
.inbox-enter-active,
.inbox-leave-active {
  transition: opacity 0.3s, transform 0.3s;
  transform-origin: center;
}
.inbox-enter, .inbox-leave-to /* .list-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: scale(0.5);
}
.form-program {
  display: flex;
  flex-direction: column;
  .input-program {
    display: flex;
    padding: 0 ($gap + 1);
    .select {
      margin-right: $gap;
    }
    .input {
      margin-right: $gap;
    }
  }
  .wrapper-program {
    display: flex;
    flex-direction: column;
    border: 1px solid $grey-lighter;
    height: 300px;
    overflow-y: scroll;
    padding: $gap;
    .row {
      display: flex;
      align-items: center;
      .select {
        margin-right: $gap;
      }
      .input {
        margin-right: $gap;
      }
    }
  }
}
</style>
